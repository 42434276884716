import {mapActions} from "vuex";

export default {
    methods : {
        ...mapActions('auth' , ['LOGIN_AS_USER']),
        async loginAsUser(userId , returnRoute = '' , enterRoute = null) {
            enterRoute = enterRoute || this.businessDashLink('')
            await this.LOGIN_AS_USER(userId)
            this.$router.push(enterRoute)
            this.$store.state.auth.spectatorTrigger++
            this.$store.state.auth.spectatorReturnRoute = returnRoute
            this.notify(this.$t('SpectatorModeOn') , 'success')
        },
    }
}