<template>
  <dash-page-new
      :root="$t('Subscribers')"
      :root-location="businessDashLink('subscribers')"
      :title=" user ? (user.firstname + ' ' + user.lastname) : $t('ClientCard') "
      :subtitle="$t('ClientCard')"
      :header-route="businessDashLink('subscribers')"
      v-model="navigation"
      :navigation-route="'subscribers/' + uuid  "
      :sideNavigation="navigationSelect">

    <template #header_action>
      <v-sheet
          :style="`border : 1px solid ${wsBACKGROUND}`"
          class="d-flex align-center pa-2 px-4 wsRoundedLight">
          <v-avatar
              v-ripple
              @click="displayUpload = true"
              class="mr-3"
              :color="!user.img ? wsBACKGROUND: 'white'"
              size="36" key="profile_picture" >

            <v-img  v-if="user.img" :src="user.img">
              <template slot="placeholder">
                <v-img src="@/assets/placeholder/user_blank.jpg" />
              </template>
            </v-img>
            <v-icon v-else size="24">mdi-account</v-icon>

          </v-avatar>
        <div>
          <h6 class="" >{{ user.email }}</h6>
          <h6 class="" >{{ user.phone }}</h6>
        </div>
        <v-btn @click="loginAsUser(user.id)" class="ml-1" :color="wsACCENT" icon>
          <v-icon>mdi-login</v-icon>
        </v-btn>

      </v-sheet>

    </template>


    <template #default>
      <router-view></router-view>
    </template>



  </dash-page-new>
</template>

<script>
import { mapActions , mapState} from "vuex";
import spectatorMode from "@/mixins/spectatorMode";

export default {
  name: "ClientCard",
  components : {
  },
  mixins : [spectatorMode],
  props : {
    uuid : {
      type : String,
      default : ''
    }
  },
  data() {
    return {

      navigation : 'timeline',

      user : {
        email : '',
        phone : '',
        firstname : '',
        lastname : ''
      },
      courses : 0,
      orders : 0,

    }
  },
  computed : {
    ...mapState('crmSystem',['triggerClientCard']),

    navigationSelect() {
      let items = [
        { text : this.$t('Timeline') , icon: "mdi-calendar",  value : 'timeline',  path : 'timeline', },
        { text : this.$t('Profile')  , icon: "mdi-account" ,  value : 'profile' ,  path : 'profile' , },
        { text : this.$t('Courses')  , icon: "mdi-school"  ,  value : 'courses' ,  path : 'courses' , },
        { text : this.$t('Orders')   , icon: "mdi-cart"    ,  value : 'orders'  ,  path : 'orders'  , }
      ]
      if ( !items ) { return }

      return items;
    }
  },
  watch : {
    triggerClientCard() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions( 'crmSystem', [ 'GET_BUSINESS_CLIENT' ] ),

    async initPage() {
      let result = await this.GET_BUSINESS_CLIENT(this.uuid)
      if ( !result && result === true ) {
        return
      }
      this.user = result.user
      this.orders = result.orders
      this.courses = result.courses
    }

  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>